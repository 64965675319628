// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './styles/App.scss';  // Import SCSS for global styles

import { ProgressSpinner } from 'primereact/progressspinner';

// Correct imports for Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay, Parallax } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/parallax';



import PostDetails from './PostDetails';
import MenubarComponent from './Component/MenubarComponent';
import { Helmet } from 'react-helmet'; // Import Helmet
import Footer from './Component/Footer';

function App() {
  const [posts, setPosts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(51);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  

  useEffect(() => {
    const offset = first;
    setIsLoading(true); // Start loading
    fetch(`https://py.afaa.website/blog/api/game?offset=${offset}&limit=${rows}&order_by=ID&order_direction=DESC`)
      .then(response => response.json())
      .then(data => {
        setPosts(data);
        setTotalRecords(1000);
        setIsLoading(false); // Stop loading
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Stop loading on error
      });
  }, [first, rows]);


  useEffect(() => {
    const fetchData = async (date) => {
      setIsLoading(true);
      try {
        const url = `https://py.afaa.website/blog/api/game?order_by=ID&order_direction=DESC&modified=${date}`;
        const response = await fetch(url);
        const data = await response.json();
        setIsLoading(false);
        return data;
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        return [];
      }
    };

    const getDate = (days) => {
      const date = new Date();
      date.setDate(date.getDate() + days);
      return date.toISOString().split('T')[0];
    };

    const datesToFetch = [3, 4, 5].map(getDate); // Today, 3 days ahead, and 4 days ahead

    Promise.all(datesToFetch.map(fetchData)).then(dataSets => {
      const combinedPosts = dataSets.flat();
      setPosts(combinedPosts);      
    });
  }, []);

  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  // Extract ID from URL search parameters
  const queryParams = new URLSearchParams(window.location.search);
  const postId = queryParams.get('id');



  return (

    <Router>
      <div className="App">
        <MenubarComponent />

        <Helmet>
        
           <title> KHONRAKGAME แหล่งรวมข้อมูลที่ครบครันสำหรับทุกคนที่หลงใหลในโลกของเทคโนโลยี และนวัตกรรมดิจิทัล! ไม่ว่าคุณจะเป็นผู้ที่ชื่นชอบ | UBON.CITY </title> 

           <meta name="description" content="ติดตามข่าวสารล่าสุด KHONRAKGAME ในวงการเกมทั้งในประเทศ และต่างประเทศ นำเสนอข่าวสารเกมใหม่ เกมมือถือ อัปเดตเกมดัง อีสปอร์ต และอื่นๆ อีกมากมาย | UBON.CITY" /> 
           
          <meta name="keywords" content="ข่าวเกม , เกมมือถือ , อีสปอร์ต , เกมใหม่ , รับทำเว็บไซต์, รับทำ SEO , รับพัฒนาระบบ , DEVELOPER , UBON.CITY , KHONRAKGAME , คนรักเกม " />

          <meta property="og:title" content="KHONRAKGAME: ข่าวเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน!" />
          <meta property="og:description" content="แหล่งรวมข่าวสารเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน! เข้าถึงทุกวงการเกม พร้อมความรู้และชุมชน | UBON.CITY" />
          <meta property="og:image" content="https://afaa.website/s/ce99ba.webp" />
          <meta property="og:url" content="https://www.ubon.city/" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@AFRAAPACHE" />
          <meta name="twitter:title" content="KHONRAKGAME: ข่าวเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน!" />
          <meta name="twitter:description" content="แหล่งรวมข่าวสารเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน! เข้าถึงทุกวงการเกม พร้อมความรู้และชุมชน | UBON.CITY" />
          <meta name="twitter:image" content="https://afaa.website/s/ce99ba.webp" />

          <link rel="canonical" href="https://www.ubon.city/" />


        </Helmet>

        <script type="application/ld+json">
          {`
  {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    "headline": "KHONRAKGAME: ข่าวเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน!",
    "image": [
      "https://afaa.website/s/ce99ba.webp"
    ],
    "datePublished": "2024-04-26",
    "dateModified": "2024-04-26"
  }
`}
        </script>

        <Routes>
          <Route path="/" element={
            
            <div className="content">
              {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <ProgressSpinner />
                </div>
              ) : (
                <>
                  {postId ? (
                    <PostDetails postId={postId} />                    
                  ) : (
                    <div className="container mt-5">
                      <h1>BLOG.TTT-WEBSITE GAME ชุมชนเกมเมอร์ ติดตามทุกการเปิดตัวเกม</h1>

                      <p>
                        แหล่งรวมข้อมูลที่ครบครันสำหรับทุกคนที่หลงใหลในโลกของเทคโนโลยี และนวัตกรรมดิจิทัล ไม่ว่าคุณจะเป็นผู้ที่ชื่นชอบ ข่าวเทคโนโลยี นักพัฒนา นักเล่นเกม หรือต้องการติดตามเทรนด์ล่าสุดในวงการเทคโนโลยี เรามุ่งมั่นที่จะเป็นแหล่งข้อมูลที่ครบครัน และน่าเชื่อถือ
                      </p>

                      <Swiper
                        modules={[Navigation, Pagination, Scrollbar, Autoplay, Parallax]}
                        spaceBetween={50}
                        navigation
                        pagination={false}
                        scrollbar={{ draggable: true }}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false
                        }}
                        parallax={true}
                        speed={800}

                        breakpoints={{
                          // When window width is <= 640px, display 1 slide per view
                          640: {
                            slidesPerView: 1
                          },
                          // When window width is >= 641px, display 2 slides per view
                          641: {
                            slidesPerView: 2
                          }
                        }}
                      >
                        {posts.map((post) => (

                          <SwiperSlide key={post.ID} className="custom-swiper-slide">
                             <Link to={`/post/${post.ID}/${(post.post_title)}`}>
                              <div className="custom-image-wrapper">
                                <img src={post.images[0]?.guid} alt={post.post_title} className="custom-image" />
                                <h5 className="text-center mt-2 color-index1">{post.post_title}</h5>
                              </div>

                              </Link>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className="row">
                        {posts.map(post => (
                          <div key={post.ID} className="col-md-4 mb-4">

                            <Link to={`/post/${post.ID}/${(post.post_title)}`}>
                              <Card style={{ background: '#202020', color: '#ababab' }} title={post.post_title} subTitle={post.post_excerpt}>
                                <img alt={post.post_title} src={post.images[0]?.guid} onError={(e) => e.target.src = 'https://afaa.website/s/ce99ba.webp'} style={{ width: '100%', cursor: 'pointer' }} />
                              </Card>
                            </Link>

                          </div>
                        ))}
                      </div>

                      <Paginator className='mb-5' first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} rowsPerPageOptions={[51, 102, 153]}></Paginator>
                    </div>
                  )}
                </>
              )}
            </div>
          } />

          
          <Route path="/post/:id/:slug" element={<PostDetails />} />
        </Routes>

        <Footer />
      </div>
    </Router>


  );

}

export default App;
