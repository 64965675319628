// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/MenubarComponent.js

import React from 'react';
import { Menubar as PrimeMenubar } from 'primereact/menubar';

const MenubarComponent = () => {

  const items = [
    { label: 'Home', icon: 'pi pi-fw pi-home', command: () => { window.location.href = "/"; } },

    { label: 'ข่าวเทคโนโลยี', icon: 'pi pi-fw pi-globe', command: () => { window.location.href = "https://blog.ttt-website.com/technology/"; } },
    { label: 'AI ปัญญาประดิษฐ์', icon: 'pi pi-fw pi-android', command: () => { window.location.href = "https://blog.ttt-website.com/ai/"; } },
    { label: 'ข่าว ESPORT', icon: 'pi pi-fw pi-users', command: () => { window.location.href = "https://blog.ttt-website.com/esport/"; } },
    { label: 'ข่าวเกม', icon: 'pi pi-fw pi-box', command: () => { window.location.href = "https://blog.ttt-website.com/game/"; } },
    { label: 'รับพัฒนาระบบ', icon: 'pi pi-fw pi-code', command: () => { window.location.href = "https://dev.rampagesoft.com/"; } },
    { label: 'รับทำ SEO', icon: 'pi pi-fw pi-search', command: () => { window.location.href = "https://seo.rampagesoft.com/"; } },
    { label: 'รับทำเว็บไซต์', icon: 'pi pi-fw pi-desktop', command: () => { window.location.href = "https://www.ttt-website.com/"; } },
    { label: 'รับออกแบบเว็บไซต์', icon: 'pi pi-fw pi-desktop', command: () => { window.location.href = "https://www.rampagesoft.com/"; } },
  ];

  const logo = (

    <a href="/" onClick={(e) => { e.preventDefault(); window.location.href = "/"; }}>
      <img alt="KHONRAKGAME LOGO" src="https://afaa.website/s/ce99ba.webp" style={{ width: '50px' }} />
    </a>

  );


  return (
    <PrimeMenubar model={items} start={logo} />
  );
};

export default MenubarComponent;
