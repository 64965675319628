// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/PostDetails.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar'; // For loading indicator
import DOMPurify from 'dompurify';
import 'primereact/resources/themes/saga-blue/theme.css'; // Import theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons

import { Button } from 'primereact/button'; // Import Button component
import './styles/PostDetails.scss';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet'; // Import Helmet

const PostDetails = ({ postId }) => {
  const { id } = useParams();

  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    fetch(`https://py.afaa.website/blog/api/game?limit=1&ID=${id}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          const contentWithStyledImages = styleImages(data[0].post_content);
          const sanitizedContent = DOMPurify.sanitize(contentWithStyledImages);
          setPost({ ...data[0], post_content: sanitizedContent });

          //const sanitizedContent = DOMPurify.sanitize(data[0].post_content);
          //setPost({ ...data[0], post_content: sanitizedContent });
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }, [id]);

  // Function to add styles to <img> tags
  const styleImages = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    doc.querySelectorAll('img').forEach(img => {
      img.style.width = '100%';
      img.style.height = 'auto';
    });
    return doc.body.innerHTML;
  };



  // Define a loading spinner component or use an existing one
  if (isLoading) {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
  }


  // Custom header with back button and title
  const cardTitle = (
    <div className="card-header">
      <Button icon="pi pi-chevron-left" className="p-button-text" onClick={() => window.history.back()} aria-label="Back" />
      <h2>{post?.post_title}</h2>
    </div>
  );

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const backgroundImageStyle = post?.images[0]?.guid ? {
    backgroundImage: `url(${post.images[0].guid})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'space',
    boxShadow: 'inset 0 0 90px 100px rgb(0 0 0 / 65%)' // This adds a glowing effect
  } : {};

  return (
    <div className="postDetailsContainer">

      <Helmet>
        <title>{post.post_title} | KHONRAKGAME</title>
     

        <meta name="description" content={`${post.post_title} - แหล่งรวมข้อมูลที่ครบครันสำหรับทุกคนที่หลงใหลในโลกของเทคโนโลยี และนวัตกรรมดิจิทัล | blog.ttt-website.com`} />

        <meta name="keywords" content={post.keywords} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={post?.post_title} />
        <meta property="og:description" content={post?.post_title} />

        <meta property="og:image" content={post?.images[0]?.guid || 'https://afaa.website/s/d45f5f.png'} />
        <meta property="og:image:secure_url" content={post?.images[0]?.guid || 'https://afaa.website/s/d45f5f.png'} />
        <meta property="og:image:alt" content={post?.post_title} />
        <meta property="og:url" content={window.location.href} />
        
        <meta name="twitter:title" content={post.post_title} />
        <meta name="twitter:description" content={`${post.post_title} - แหล่งรวมข้อมูลที่ครบครันสำหรับทุกคนที่หลงใหลในโลกของเทคโนโลยี และนวัตกรรมดิจิทัล | blog.ttt-website.com`} />
      </Helmet>


      <div className="content" style={backgroundImageStyle}>
        <div className="container mt-5">


          {post && (
            <Card title={cardTitle} subTitle={post.post_excerpt}> 
            <span>Content ID : {post.ID} | {post.post_modified} | <a className='color-index2' href='https://blog.ttt-website.com/'>BLOG.TTT-WEBSITEM</a></span>
              {post.images && post.images.length > 0 && (
                <img className='pointer' src={post.images[0].guid} alt={post.post_title} style={{ width: '100%' }} />
              )}
              
              <div className='content-index1'  dangerouslySetInnerHTML={{ __html: post.post_content }} />

              <div className="card-footer footer-index1 text-center mt-5">
                {/* Display post modified date */}
                <span>Last Modified: {post.post_modified}</span> -
                <a className='color-index2' href='https://www.siamwebsite.me/seo/'> SEO Thailand</a>
              </div>

            </Card>
          )}
          {/* Back button or navigation */}
      
        </div>
      </div>
      
    </div>
  );
};

export default PostDetails;
